<template>
  <div>
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex lg4 pr-6 pl-6>
          <v-layout row wrap justify-end>
            <v-menu
                v-model="menuStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                >
                <template v-slot:activator="{ on }">
                    <v-text-field
                    v-model="computedStartDateFormatted"
                    label="Từ ngày"
                    hint="DD-MM-YYYY"
                    persistent-hint
                    prepend-icon="fa-calendar-alt"
                    readonly
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker v-model="dateRange.startDate" no-title @input="menuStart = false"></v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex lg4 pr-6>
          <v-layout row wrap justify-end>
            <v-menu
                v-model="menuEnd"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
                >
                <template v-slot:activator="{ on }">
                    <v-text-field
                    v-model="computedEndDateFormatted"
                    label="Đến ngày"
                    hint="DD-MM-YYYY"
                    persistent-hint
                    prepend-icon="fa-calendar-alt"
                    readonly
                    v-on="on"
                    ></v-text-field>
                </template>
                <v-date-picker v-model="dateRange.endDate" no-title @input="menuEnd = false"></v-date-picker>
            </v-menu>
          </v-layout>
        </v-flex>
        <v-flex lg4 pr-6>
            <v-btn @click="filter_data" color="#a77800" outlined>Xem</v-btn>
        </v-flex>
        <v-flex lg12>
             <template v-for="(item, i) in items">
                <div v-bind:key="i">
                <h4>Tháng: {{item.month.replace(/(\d{4})(\d{2})/, "$2-$1")}}</h4>
                <v-card class="mb-8">
                    <v-data-table fixed-header height="750px"   hide-default-footer :headers="headers" :items="item.rows" :server-items-length="item.rows.length" :must-sort="true" :loading="loading" :mobile-breakpoint="100">
                        <template v-slot:item="{ item }">
                            <tr>
                                <td>{{ item.StoreID | display_value(listStores) }}</td>
                                <td class="text-end">{{ item.TotalBag | currency }}</td>
                                <td class="text-end">{{ item.TotalWeightCN | currency('', 1)}}</td>
                                <td class="text-end">{{ item.TotalPackageCN | currency}}</td>
                                <td class="text-end">{{ item.TotalPrice | currency}}</td>
                                <td class="text-end">{{ item.TotalWeightVN | currency('', 1)}}</td>
                                <td class="text-end">{{ item.TotalPackageVN | currency}}</td>
                                <td class="text-end" v-if="roleAdmin">{{ item.Amount | currency}}</td>
                                <td class="text-end">{{ item.DiffWeight | currency('', 1)}}</td>
                                <td class="text-end" v-if="roleAdmin">{{ item.Revenue | currency}}</td>
                                <td class="text-end">{{ item.KPI | currency('', 2)}}</td>
                                <td class="text-end">{{ item.Reward | currency}}</td>
                            </tr>
                        </template>
                        <template v-slot:body.append>
                            <tr>
                                <td class="font-weight-bold">TỔNG</td>
                                <td class="text-end font-weight-bold">{{dic_summary[item.month].TotalBag | currency}}</td>
                                <td class="text-end font-weight-bold">{{dic_summary[item.month].TotalWeightCN | currency('', 1)}}</td>
                                <td class="text-end font-weight-bold">{{dic_summary[item.month].TotalPackageCN | currency}}</td>
                                <td class="text-end font-weight-bold">{{dic_summary[item.month].TotalPrice | currency}}</td>
                                <td class="text-end font-weight-bold">{{dic_summary[item.month].TotalWeightVN | currency('', 1)}}</td>
                                <td class="text-end font-weight-bold">{{dic_summary[item.month].TotalPackageVN | currency}}</td>
                                <td class="text-end font-weight-bold"  v-if="roleAdmin">{{dic_summary[item.month].Amount | currency}}</td>
                                <td class="text-end font-weight-bold">{{dic_summary[item.month].DiffWeight | currency('', 1)}}</td>
                                <td class="text-end font-weight-bold"  v-if="roleAdmin">{{dic_summary[item.month].Revenue | currency}}</td>
                                <td class="text-end font-weight-bold">{{dic_summary[item.month].KPI | currency('', 2)}}</td>
                                <td class="text-end font-weight-bold">{{dic_summary[item.month].Reward | currency}}</td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-card>
                </div>
            </template>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import { mapState } from "vuex";
import configs from "../../configs";
import Filtering from "@/components/Filtering";
import { request_list } from '@/commons/hpod';
import Vue from 'vue';
import _ from "lodash";
import moment from "moment"
export default {
    data() {
        return {
            pagination: {  ...configs.PAGINATION,  sortBy: ["ID"]  },
            items_per_page: _.cloneDeep(configs.ITEMS_PER_PAGE),
            viewName: "thong-ke-phi-van-chuyen",
            dateRange: {
                startDate: moment.utc(Date.now()).startOf('month').toISOString().substr(0, 10),
                endDate: moment.utc(Date.now()).endOf('month').toISOString().substr(0, 10),
            },
            menuStart: false,
            menuEnd: false,
        };
    },
    watch: {
        pagination: {
            handler: function (val, oldVal) {
                let filterConditions = this.$store.state.commons.filterConditions;
                this.filterConditions = !_.isEmpty(filterConditions) ? filterConditions[this.viewName] : []
                this.filter_data();
            },
            deep: true
        },
    },
    computed: {
        ...mapState({
            items: state => state.report.orderTransport.data,
            loading: state => state.report.loading,
            warehouses: state => state.commons.stores,
        }),
        computedStartDateFormatted () {
            return moment(this.dateRange.startDate).format(configs.SHORT_DATE_FORMAT);
        },
        computedEndDateFormatted () {
            return moment(this.dateRange.endDate).format(configs.SHORT_DATE_FORMAT);
        },
        roleAdmin() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
        },
        dic_summary() {
            let dic = {};
            for (let i = 0; i < this.items.length; i++) {
                const item = this.items[i];
                dic[item.month] = {
                    'TotalBag': _.sumBy(item.rows, 'TotalBag'),
                    'TotalWeightCN': _.sumBy(item.rows, 'TotalWeightCN'),
                    'TotalPackageCN': _.sumBy(item.rows, 'TotalPackageCN'),
                    'TotalPrice': _.sumBy(item.rows, 'TotalPrice'),
                    'TotalWeightVN': _.sumBy(item.rows, 'TotalWeightVN'),
                    'TotalPackageVN': _.sumBy(item.rows, 'TotalPackageVN'),
                    'Amount': _.sumBy(item.rows, 'Amount'),
                    'DiffWeight': _.sumBy(item.rows, 'DiffWeight'),
                    'Revenue': _.sumBy(item.rows, 'Revenue'),
                    'Reward': _.sumBy(item.rows, 'Reward'),
                    'KPI': 0
                }
                dic[item.month].KPI = dic[item.month].TotalDiff/dic[item.month].TotalAmount*100;
            }
            return dic;
        },
        headers() {
            var hd = [
                {
                    text: "Kho",
                    value: "StoreID",
                    filterable: true,
                    quickSearch: true,
                    dataType: configs.DATA_TYPE["Enum"],
                    values: this.listStores
                },
                {
                    text: "Tổng bao hàng",
                    value: "TotalBag",
                    align: "end",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Tổng cân TQ",
                    value: "TotalWeightCN",
                    align: "end",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Số kiện TQ",
                    value: "TotalPackageCN",
                    align: "end",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Chi phí",
                    value: "TotalPrice",
                    align: "end",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Tổng cân VN",
                    value: "TotalWeightVN",
                    align: "end",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Number"]
                },
                {
                    text: "Số kiện VN",
                    value: "TotalPackageVN",
                    align: "end",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Number"]
                }
            ];
            if(this.roleAdmin) {
                hd.push({
                    text: "Doanh thu",
                    value: "Amount",
                    align: "end",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Number"]
                });
            }
            
            hd.push({
                text: "Chênh lệch cân",
                value: "DiffWeight",
                align: "end",
                filterable: true,
                dataType: configs.DATA_TYPE["Number"]
            });
            if(this.roleAdmin) {
                hd.push({
                    text: "Lãi cân nặng",
                    value: "Revenue",
                    align: "end",
                    filterable: true,
                    dataType: configs.DATA_TYPE["Number"]
                });
            }
            hd.push({
                text: "KPI",
                value: "KPI",
                filterable: true,
                dataType: configs.DATA_TYPE["Number"]
            });
            hd.push({
                text: "Thưởng",
                value: "Reward",
                align: "end",
                filterable: true,
                dataType: configs.DATA_TYPE["Number"]
            });
            return hd;
        },
        listStores() {
            return _.map(this.warehouses, function(item) {
                return {value: item.ID, name: item.Name, shortname: item.ShortName}
            });
        }
    },
    methods: {
        filter_data: function () {
            var param = {
                startTime: moment(this.dateRange.startDate).format(configs.SQL_DATE_FORMAT),
                endTime: moment(this.dateRange.endDate).format(configs.SQL_DATE_FORMAT),
                pagination: this.pagination
            };
            this.$store.dispatch("report/getOrderTransport", param);
        }
    },
    mounted() {
        this.$store.dispatch("commons/getAllStores");
        this.filter_data();
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_REFRESH_DATA", this.filter_data);
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_REFRESH_DATA", this.filter_data);
    }
};
</script>